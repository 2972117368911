/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import { ApiProvider } from "@fabriqo/api-rest";

import IdentityProvider from "../../contexts/IdentityContext";
import LoginProvider, { useLoginContext } from "../../contexts/LoginContext";

const FABRIQO_APP_BASE_URL = process.env.NODE_ENV === 'development' ?
    `https://dev.fabriqo.com:3000/api/v2/apps/5bb4c620-582b-4f08-9238-a64883219426/` :
    `https://app.fabriqo.com/api/v2/apps/${process.env.GATSBY_FABRIQO_APP_ID}/`;

export const withLayout = Component => (
  props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )
);

const InnerLayout = props => {
  const { children } = props;
  const { fabriqoClient, logout } = useLoginContext();

  return (
    <ApiProvider baseURL={FABRIQO_APP_BASE_URL} client={fabriqoClient} onUnauthorized={logout}>
      {children}
    </ApiProvider>
  );
};

const Layout = props => (
  <IdentityProvider>
    <LoginProvider>
      <InnerLayout {...props}/>
    </LoginProvider>
  </IdentityProvider>
);

export default Layout;
