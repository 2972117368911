import { Response, OAuthError } from "@zalando/oauth2-client-js";
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { api, authorize, getFabriqo, requestAuthorization } from "../services/auth";

const defaultState = {
  token: undefined,
  identity: undefined,
  logout: undefined,
};

const IdentityStateContext = createContext(null);

const IdentityProvider = props => {
  const { children } = props;
  
  const [identity, setIdentity] = useState(getFabriqo().storage.get('identity'));
  const [error, setError] = useState(null);
  const [token, setToken] = useState(getFabriqo().getAccessToken());
  const [requestedRoute, setRequestedRoute] = useState(null);

  const logout = useCallback(() => {
    getFabriqo().deleteTokens();
    getFabriqo().storage.remove('identity');
    setIdentity(null);
    setToken(null);
  }, []);

  const initiateLogin = useCallback(() => {
    requestAuthorization({ requestedRoute });
  }, [requestedRoute]);

  const login = useCallback(formData => {
    setError(null);
    return authorize({ requestedRoute }, formData)
      .then(({ data }) => {
        const response = data.error ? new OAuthError(data) : new Response(data);
        getFabriqo().handleResponse(response);
        completeLogin(response);
        return response;
      })
      .catch(err => {
        const error = { error: err, description: 'Invalid credentials' };
        setError(error);
        return error;
      });
  }, [requestedRoute]);

  const completeLogin = useCallback(async payload => {
    setToken(payload.access_token);
    const client = api();

    const { data } = await client.get('/userinfo');
    getFabriqo().storage.set('identity', data);
    setIdentity(data);
  }, []);

  const client = useMemo(() => api(), [token]);

  const context = {
    client,
    error,
    identity,
    initiateLogin,
    isLoggedIn: !!token,
    login,
    completeLogin,
    logout,
    setRequestedRoute,
    token,
  };

  return (
    <IdentityStateContext.Provider value={context}>
      {children}
    </IdentityStateContext.Provider>
  );
};

export const useIdentityStateContext = () => {
  const context = useContext(IdentityStateContext);

  if (context === undefined) {
    throw new Error('useIdentityStateContext must be used within a IdentityProvider');
  }

  return context;
};

export default IdentityProvider;
