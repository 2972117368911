import axios from "axios";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { getLocal, storeLocal, subscribeLocal, unsubscribeLocal } from "../utils/local";

const ACCESS_TOKEN_KEY = 'depalma-token';

const defaultState = {
  token: null,
};

const LoginStateContext = createContext(null);

const LoginProvider = props => {
  const { children } = props;
  
  const [initialized, setInitialized] = useState(false);
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    const localState = getLocal(ACCESS_TOKEN_KEY);
    localState && setState(localState);
    setInitialized(true);

    subscribeLocal(ACCESS_TOKEN_KEY, setState);

    return () => {
      unsubscribeLocal(ACCESS_TOKEN_KEY, setState);
    };
  }, []);

  const setToken = useCallback((token) => {
    const updState = { ...state, token };
    storeLocal(ACCESS_TOKEN_KEY, updState);
    setState(updState);
  }, [state]);

  const getToken = useCallback(() => {
    if (initialized) {
      return state.token;
    } else {
      return getLocal(ACCESS_TOKEN_KEY);
    }
  }, [initialized, state]);

  const getIdentity = useCallback(() => {
    if (initialized) {
      return state.token?.fabriqo_metadata?.identity;
    } else {
      return getLocal(ACCESS_TOKEN_KEY)?.fabriqo_metadata?.identity;
    }
  }, [initialized, state]);

  const getAccount = useCallback(() => {
    if (initialized) {
      return state.token?.account;
    } else {
      return getLocal(ACCESS_TOKEN_KEY)?.account;
    }
  }, [initialized, state]);

  const fabriqoClient = useMemo(() => {
    const token = state.token?.fabriqo_metadata?.access_token;
    if (token) {
      return axios.create({
        baseURL: process.env.NODE_ENV === 'development' ? `https://dev.fabriqo.com:3000/api/v2/apps/5bb4c620-582b-4f08-9238-a64883219426/` : `https://app.fabriqo.com/api/v2/apps/${process.env.GATSBY_FABRIQO_APP_ID}/`,
        timeout: 10000,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    }
  }, [state]);

  const logout = useCallback(() => {
    axios.post('/.netlify/functions/logout')
      .then(() => {
        setToken(null);
      })
      .catch(err => {
        console.warn('err', err);
      })
  }, []);

  const context = {
    getToken,
    getIdentity,
    getAccount,
    setToken,
    fabriqoClient,
    logout,
  };

  return (
    <LoginStateContext.Provider value={context}>
      {children}
    </LoginStateContext.Provider>
  );
};

export const useLoginContext = () => {
  const context = useContext(LoginStateContext);

  if (context === undefined) {
    throw new Error('useLoginContext must be used within a LoginProvider');
  }

  return context;
};

export default LoginProvider;
