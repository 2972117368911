import axios from "axios";
import * as OAuth from "@zalando/oauth2-client-js";

const ssrFabriqo = {
  storage: { get: () => {}, set: () => {}, remove: () => {} },
  getAccessToken: () => {},
  deleteToken: () => {},
  parse: () => {},
  requestToken: () => {},
  remember: () => {},
};
let fabriqo = null;
export const getFabriqo = () => {
  if (typeof window === 'undefined') {
    return ssrFabriqo;
  }

  if (fabriqo) {
    return fabriqo;
  }

  return fabriqo = new OAuth.Provider({
    id: process.env.NODE_ENV === 'development' ? 'fabriqo-dev' : 'fabriqo',   // required
    authorization_url: process.env.NODE_ENV === 'development' ? 'https://dev.fabriqo.com:3000/oauth/a09c485e999d/authorize' : 'https://app.fabriqo.com/oauth/authorize' // required
  });
}

const buildRequestUri = metadata => {
  // Create a new request
  const request = new OAuth.Request({
    metadata,
    client_id:  process.env.NODE_ENV === 'development' ? 'oQK0aiX28JkXztqJexXGB-nswovcM9ajAuv0OIJ9ohI' : process.env.GATSBY_FABRIQO_CLIENT_ID,  // required
    scope: 'public',
    redirect_uri: process.env.NODE_ENV === 'development' ? `${window.location.origin}/app/oauth` : 'https://intra.depalmaworkwear.com/app/oauth'
  });

  // Give it to the provider
  var uri = getFabriqo().requestToken(request);
  // Later we need to check if the response was expected
  // so save the request
  getFabriqo().remember(request);

  return uri;
};

export const authorize = (metadata, formData) => {
  if (typeof window === 'undefined') return;

  const uri = buildRequestUri(metadata);

  const client = axios.create({
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return client.post(uri, formData);
};

export const requestAuthorization = metadata => {
  if (typeof window === 'undefined') return;

  const uri = buildRequestUri(metadata);

  // Do the redirect
  window.location.href = uri;
}

export const isLoggedIn = () => {
  return !!getFabriqo().getAccessToken();
};

export const api = () => {
  const token = getFabriqo().getAccessToken();
  return axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? `https://dev.fabriqo.com:3000/api/v2/apps/5bb4c620-582b-4f08-9238-a64883219426/` : `https://app.fabriqo.com/api/v2/apps/${process.env.GATSBY_FABRIQO_APP_ID}/`,
    timeout: 10000,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

// export class Account {
//   constructor(claims = {}) {
//     this.claim = claims;
//   }
// }